import React, { useState, useEffect, useReducer, useCallback } from "react";
import GoogleMapReact from "google-map-react";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import { FaCar } from "react-icons/fa";
import { IconContext } from "react-icons";
import config from "../config";
import { useAppContext } from "../libs/contextLib";
import {usePosition} from '../usePosition';
import carInfoData_kona_1 from "../data/carinfo_kona_1.json"
import carInfoData_kona_2 from "../data/carinfo_kona_2.json"
import carInfoData_kona_3 from "../data/carinfo_kona_3.json"
import carInfoData_kona_4 from "../data/carinfo_kona_4.json"
import carInfoData_ka4_4 from "../data/carinfo_ka4_4.json"
import carInfoData_nave_001 from "../data/carinfo_nave_001.json"
import { useTranslation } from 'react-i18next';

const carInfoReducer = (prevState, action) => {
  let array;
  switch (action.type) {
    case 'ADD':
      array = [...prevState];
      const indexOfObject = array.findIndex(function (data) { return data.vehicle_id === action.payload.vehicle_id });
      
      if (indexOfObject !== -1) {
        array.splice(indexOfObject, 1);
        array.push(action.payload);
      } else {
        array.push(action.payload);
      }
      //console.log(indexOfObject);
      //console.log(array.find( function(data) { return data.vehicle_id === action.payload.vehicle_id } ));
      //console.log(action.payload);
      
      return array;
    case 'REMOVE':
      array = [...prevState];
      array.pop();
      return array;
    case 'CLEAR':
      return prevState = [];
    default:
      break;
  }
};

// InfoWindow component
const InfoWindowStation = (props) => {
  const { place } = props;
  const infoWindowStyle = {
    position: 'relative',
    bottom: 40,
    left: '-4px',
    width: 150,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 0,
    fontSize: 10,
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 12, color: 'orange' }}>
        {place.name}
      </div>
    </div>
  );
};

function GoogleMapAPI(props) {
  const [placeStations, setPlaceStations] = useState([]);
  const { t } = useTranslation();
  const { routeAppContext } = useAppContext();
  const { routeMap, setRouteMap } = useAppContext();
  const { strStationAppContext } = useAppContext();
  const { endStationAppContext } = useAppContext();
  const { leadtimeAppContext } = useAppContext();
  const { routeSectionAppContext, setRouteSectionAppContext } = useAppContext();
  const [googleApiObj, setIsGoogleApiLoadedObj] = useState(null);
  const {latitude, longitude, error} = usePosition(true, {
    enableHighAccuracy: true,
    timeout: Infinity,
    maximumAge: 0,
  });
  const [carInfo, dispatcher] = useReducer(carInfoReducer, []);
  const { messageHistory } = useAppContext();
  const addCarInfoHandler = (carInfo) => {
    dispatcher({ type: 'ADD', payload: carInfo });
  }

  const clearCarInfoHandler = () => {
    dispatcher({ type: 'CLEAR', payload: "" });
  }

  useEffect(() => {
    //clearCarInfoHandler(JSON.parse(lastMessage.data).data);
    if (messageHistory) {
      if (routeAppContext.id == JSON.parse(messageHistory).data.route_id) {
        addCarInfoHandler(JSON.parse(messageHistory).data);
      }
    }
  }, [messageHistory]);

  useEffect(() => {
    if (googleApiObj) {
      const {
        map,
        maps
      } = googleApiObj;
      // or else call that isApiLoaded function and pass-on these arguments  
      //console.log(routeMap);
      //console.log(strStationAppContext.station.stationname);
      //console.log(endStationAppContext.station.stationname);
      //console.log(leadtimeAppContext);
      //console.log(routeSectionAppContext.sectionname);
      renderGeoFences(map, maps);
      clearCarInfoHandler();
      getCars();
      if (strStationAppContext && endStationAppContext) {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new maps.LatLng(
          Number(strStationAppContext.station.site_lat),
          Number(strStationAppContext.station.site_lng),
        ));
        bounds.extend(new maps.LatLng(
          Number(endStationAppContext.station.site_lat),
          Number(endStationAppContext.station.site_lng),
        ));
        map.fitBounds(bounds);
      }
    }
  }, [googleApiObj])
  
  // infowindow for station click
  /*
  useEffect(() => {
    setPlaceStations([])

    routeMap.map(data => {
      //console.log({id: 'S' + data.station.id, place: data.station, show: false});
      setPlaceStations(placeStations => [...placeStations, {id: 'S' + data.station.id, place: data.station, show: false, name: data.station.stationname}]);
    });
  }, [routeMap]);
  */

  const MarkerCar = (props) => {
    var icon_src = `${config.DJANGO_URL}/static/down_img/icon_raxi_kona_front.png`
    return (
      <IconContext.Provider
        value={{ style: { color: '#000000', fontSize: '30px' } }}
      >
        <div className="pin" style={{position: 'absolute', transform: 'translate(-50%, -100%)'}}>
          <img src={icon_src} alt="marker" width="19" height="25"/>
        </div>  
      </IconContext.Provider>
    );
  }

  const MarkerUser = (props) => {
    var icon_src = `${config.DJANGO_URL}/static/down_img/icon_raxi_human.png`
    return (
      <IconContext.Provider
        value={{ style: { color: '#000000', fontSize: '30px' } }}
      >
        <div className="pin" style={{position: 'absolute', transform: 'translate(-50%, -100%)'}}>
          <img src={icon_src} alt="marker" width="19" height="25"/>
        </div>
      </IconContext.Provider>
    );
  }

  const MarkerStrStation = (props) => {
    var icon_src = `${config.DJANGO_URL}/static/down_img/icon_raxi_poi_red.png`
    return (
      <IconContext.Provider
        value={{ style: { color: '#000000', fontSize: '30px' } }}
      >
        <div className="pin" style={{position: 'absolute', transform: 'translate(-50%, -100%)'}}>
          <img src={icon_src} alt="marker" width="19" height="25"/>
        </div>
      </IconContext.Provider>
    );
  }

  const MarkerEndStation = (props) => {
    var icon_src = `${config.DJANGO_URL}/static/down_img/icon_raxi_poi_blue.png`
    return (
      <IconContext.Provider
        value={{ style: { color: '#000000', fontSize: '30px' } }}
      >
        <div className="pin" style={{position: 'absolute', transform: 'translate(-50%, -100%)'}}>
          <img src={icon_src} alt="marker" width="19" height="25"/>
        </div>
      </IconContext.Provider>
    );
  }

  function getCars() {
    try {
      axiosInstance.get('/robotaxi/robocar_run/', {
        params: { route_id: routeAppContext.id, section_id: routeSectionAppContext.id }
      }).then(function (response) {
        let carinfo;
        response.data.map( (data) => {
          switch (data.vehicle_id) {
            case 'kona_1':
              carinfo = carInfoData_kona_1;
              break;
            case 'kona_2':
              carinfo = carInfoData_kona_2;
              break;
            case 'kona_3':
              carinfo = carInfoData_kona_3;
              break;
            case 'kona_4':
              carinfo = carInfoData_kona_4;
              break;
            case 'nave_001':
              carinfo = carInfoData_nave_001;
              break;
            default:
              carinfo = carInfoData_ka4_4;
          }
          carinfo.data.route_id = routeAppContext.id
          carinfo.data.vehicle_id = data.vehicle_id
          carinfo.data.latitude = Number(data.site_lat);
          carinfo.data.longitude = Number(data.site_lng);
          addCarInfoHandler(carinfo.data)
        });
      }).catch(function (error) {
        //console.log(error);
      });
    } catch (e) {
      onError(e);
    }
  }

  async function createCenterControl(map, maps) {
    const centerControlDiv = document.createElement('div');
    const controlButton = document.createElement('text');
  
    // Set CSS for the control.
    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = '2px solid #fff';
    controlButton.style.borderRadius = '10px';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.color = 'rgb(25,25,25)';
    //controlButton.style.cursor = 'pointer';
    //controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlButton.style.fontSize = '14px';
    controlButton.style.lineHeight = '38px';
    controlButton.style.margin = '8px 0 22px';
    controlButton.style.padding = '0 5px';
    controlButton.style.textAlign = 'center';
    controlButton.textContent = t('ETA') + ': ' + leadtimeAppContext + t('MINUTE');
    //controlButton.title = 'Click to recenter the map';
    //controlButton.type = 'button';
  
    // Setup the click event listeners: simply set the map to Chicago.
    //controlButton.addEventListener('click', () => {
    //  map.setCenter(chicago);
    //});
  
    centerControlDiv.append(controlButton);
    map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
  }

  const setMarkers = async(map, maps) => {
    // Information window : Arrival time
    createCenterControl(map, maps);

    //정류장 위치 Mark
    //console.log(routeMap);
    
    const infoWindow = new maps.InfoWindow();
    const image = {
      url: "https://localhost:8081/static/down_img/o1_stop.png",
      // This marker is 20 pixels wide by 32 pixels high.
      //scaledSize: new maps.Size(19, 25),
      // The origin for this image is (0, 0).
      origin: new maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      //anchor: new maps.Point(0, 24),
    };

    /*
    const response = await axiosInstance.get('/robotaxi/routemap_list_allowany/', {
      params: { id: routeAppContext.id, section: routeSectionAppContext.id }
    });
    if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
    */
    routeMap.map((data, key) => {
      let marker = new maps.Marker({
        position: { lat: Number(data.station.site_lat), lng: Number(data.station.site_lng) },
        map,
        title: data.station.stationname,
        icon: config.DJANGO_URL + data.station.icon_src
      });
      marker.setMap(map);
      // Add a click listener for each marker, and set up the info window.
      marker.addListener("click", () => {
        infoWindow.close();
        //infoWindow.setContent(marker.getTitle());
        infoWindow.setContent(getMarkInfoWindow(data));
        infoWindow.open(marker.getMap(), marker);
      });
    })

  }

  const getMarkInfoWindow = (data) => `
    <div>
      <div style="font-size: 12px;">
        ${data.station.stationname}
      </div>
    </div>`;

  // Return map bounds based on list of places
  const getMapBounds = (map, maps) => {
    const bounds = new window.google.maps.LatLngBounds();

    routeMap.forEach((data) => {
      bounds.extend(new maps.LatLng(
        Number(data.station.site_lat),
        Number(data.station.site_lng),
      ));
    });
    map.fitBounds(bounds);
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const handleApiLoaded = (map, maps) => {
    //console.log(props.routeMap);
    // Bind the resize listener
    //bindResizeListener(map, maps, bounds);
    //setMarkers(map, maps);
    //setOverlayView(map, maps);
    //setKmlLayer(map, maps);
    //setDataLayer(map, maps);
    //setMarkersInfo(map, maps);
  };

  const setRouteDataLayer = async(map, maps) => {
    //console.log(strStationAppContext&&strStationAppContext);
    //console.log(endStationAppContext&&endStationAppContext);
    // 라우팅 구간 ID 와 선택된 노선의 라우팅 구간 ID 가 같은 경우에 정류장별 구간 제어
    // 이외에는 노선도와 색깔 표시
    const legend = document.getElementById("legend");
    const response = await axiosInstance.get('/robotaxi/routesection_list_allowany/',{
      params: {route_id: routeAppContext.id, is_publish: '1'}
    });
    if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";

      response.data.map( (data_row) => {
        var layer = new maps.Data();

        layer.setMap(map);
        layer.loadGeoJson(
          `${config.DJANGO_URL}${data_row.mapurl}`
        );
        if ((routeAppContext.id == data_row.route.id) && (routeSectionAppContext.id == data_row.id)) {
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            var s_check = 99999999
            var e_check = 99999999
            routeMap.map( (data_row) => {
              if (strStationAppContext.station.id == data_row.station.id) {
                s_check = Number(data_row.sectionno)
              }
              if (endStationAppContext.station.id == data_row.station.id) {
                e_check = Number(data_row.sectionno)
              }
            });
            layer.setStyle(function(feature) {
              var section = feature.getProperty('section');
              var color = data_row.sectioncolor;
              var weight = 3;
              if ( s_check > e_check) {
                if (section >= s_check) {
                  color = 'red';
                  weight = 2;
                } else {
                  color = data_row.sectioncolor;
                  weight = 3;
                  if (section < e_check) {
                    color = 'red';
                    weight = 2;
                  } else {
                    color = data_row.sectioncolor;
                    weight = 3;
                  }
                }
              } else {
                if (section >= s_check) {
                  color = 'red';
                  weight = 2;
                  if (section < e_check) {
                    color = 'red';
                    weight = 2;
                  } else {
                    color = data_row.sectioncolor;
                    weight = 3;
                  }
                } else {
                  color = data_row.sectioncolor;
                  weight = 3;
                }
              }
              
              return {
                strokeColor: color,
                strokeWeight: weight
              };
            });
        } else {
          layer.setStyle({
            strokeColor: `${data_row.sectioncolor}`,
            fillColor: `${data_row.sectioncolor}`,
            strokeWeight: 1
          });
        }

        const div = document.createElement("div");
        div.innerHTML = `<font color='${data_row.sectioncolor}'>${data_row.sectionname}</font>`;
        legend.append(div);
      });

    map.controls[maps.ControlPosition.RIGHT_TOP].push(legend);
  }

  const setKmlLayer = (map, maps) => {
    const kmlLayer = new maps.KmlLayer({
      url: `${config.DJANGO_URL}/robotaxi/inner/static/?filename=c3_safty_v2.kml`,
      map: map,
    });
  }

  const getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: false,
      fullscreenControl: false,
      styles: [{
        featureType: "poi.business",
        elementType: "labels",
        stylers: [{
          visibility: "off"
        }]
      }],
      gestureHandling: "greedy",
      disableDoubleClickZoom: true,

      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.LEFT_TOP,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
          maps.MapTypeId.TERRAIN
        ]
      },

      zoomControl: false,
      clickableIcons: false
    };
  }

  // onChildClick callback can take two arguments: key and childProps
  const onChildClickCallback = (key) => {
    if (key.slice(0,1) == 'S') {
      setPlaceStations((placeStations) => {
        const index = placeStations.findIndex((e) => e.id === key);
        placeStations[index].show = !(placeStations[index].show); // eslint-disable-line no-param-reassign
        return placeStations;
      });  
    }
  };

  function renderGeoFences(map, maps) {
    setMarkers(map, maps);
    setRouteDataLayer(map, maps);
  }

  return (
    <>
      <div style={{ width: '100%', height: '30vh' }}>
      { routeAppContext &&
        <GoogleMapReact
          className="react-map"
          options={getMapOptions}
          bootstrapURLKeys={{
            key: "AIzaSyCjJQ1UO0BgWFnOgtvTinQ4PHzu8FoSVO0",
            libraries: ['places', 'geometry', 'drawing', 'visualization'],
          }}
          defaultCenter={{ lat: Number(routeAppContext.ref_lat), lng: Number(routeAppContext.ref_lng) }} // 지도 초기 위치
          defaultZoom={Number(routeAppContext.defalt_zoom)} // 지도 초기 확대 배율
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            setIsGoogleApiLoadedObj({
              map,
              maps
            })
            //handleApiLoaded(map, maps);
            // access to visualization methods within `map.visualization`
          }
          }
          onChildClick={onChildClickCallback}
        >
          {/*
        { placeStations && placeStations.map(data => (
          <MarkerStation
            key={data.id}
            lat={data.place.site_lat}
            lng={data.place.site_lng}
            text={data.place.stationname}
            tooltip={data.place.stationname}
            icon_src={`${config.DJANGO_URL}` + data.place.icon_src}
            show={data.show}
            place={data}
          />
        ))
        }
      */}
        { latitude && 
          <MarkerUser
            key="U"
            lat={latitude}
            lng={longitude}
            text="현위치"
          />
        }
        { strStationAppContext && 
          <MarkerStrStation
            key="MS"
            lat={strStationAppContext.station.site_lat}
            lng={strStationAppContext.station.site_lng}
            text={strStationAppContext.station.stationname}
          />
        }
        { endStationAppContext && 
          <MarkerEndStation
            key="ME"
            lat={endStationAppContext.station.site_lat}
            lng={endStationAppContext.station.site_lng}
            text={endStationAppContext.station.stationname}
          />
        }
        { carInfo && carInfo.map( (data) => {
              return (
                <MarkerCar
                  key={data.vehicle_id}
                  lat={data.latitude}
                  lng={data.longitude}
                  text={data.vehicle_id}
                  tooltip={data.vehicle_id}
                /> )          
          })
        }
        </GoogleMapReact>
      }
      </div>
      <div id="legend"><b>{t('LINEINFO')}</b></div>
    </>
  );
}

export default GoogleMapAPI;